html,
body {
  font-family: sans-serif;
  margin: 0;
  padding: 12px;
  color: #333;
  background-color: #fff;
}

:root {
  --primary-color: #0069f6;
  --primary-light-color: #d1e4fd;
  --background-color: #fff;
  --separator-color: #ccc;
}

button {
  font-size: 1em;
  color: var(--primary-color);
  background-color: var(--background-color);
  border: 1px solid var(--primary-color);
  padding: 8px 20px;
  cursor: pointer;
}

button:hover {
  color: var(--background-color);
  background-color: var(--primary-color);
  transition: 0.2s;
}

h1 {
  font-weight: 300;
  border-bottom: 1px solid var(--separator-color);
}

.clickable-path {
  cursor: pointer;
  fill: #d1e4fd;
  stroke: white;
  stroke-width: 6px;
  transition: all 0.2s ease-out;
}

.clickable-path:hover {
  fill: #0069f6;
}

.clickable-path.selected {
  fill: red;
}

.district-select {
  color: var(--primary-color);
  background-color: var(--primary-light-color);
  font-weight: 900;
  border: 1px solid var(--primary-color);
  padding: 20px;
}

.supertext {
  background-color: yellow;
  height: 30px;
}

.custom-demo-component {
  grid-column: span 2;
  width: 100%;
  height: 100%;
  max-height: 150px;
  padding: 0;
}

.custom-demo-component .xxx {
  fill: #d1e4fd;
}

.custom-demo-component .xxx:hover {
  fill: #0069f6;
}

.grid {
  padding: 20px;
}

.num-percent,
.custom-num-percent {
  color: #666;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.num-percent .label,
.custom-num-percent .label {
  text-align: center;
  font-size: 0.8em;
  padding: 8px 0;
}

.num-percent .value,
.custom-num-percent .value {
  color: #000;
  font-weight: 500;
  font-size: 1.4em;
  text-align: center;
  padding: 2px 0 8px;
}

.num-percent .bar,
.custom-num-percent .bar {
  height: 10px;
  border-radius: 0;
}

.custom-num-percent .bar {
  height: 20px;
  border-radius: 10px;
}

.num-percent .percentage,
.custom-num-percent .percentage {
  text-align: center;
  font-size: 0.8em;
  padding: 4px 0;
}

.custom-vbar {
  padding: 40px;
  background-color: #fff;
  grid-column-gap: 3px;
  min-height: 160px;
  max-width: 600px;
}

.custom-vbar div {
  background-color: var(--primary-color);
  border-radius: 2px;
}

.sm-grid2 {
  grid-template-columns: 3fr 1fr;
  grid-gap: 20px;
}

.abc-sm-grid2 {
  grid-template-columns: 8fr 4fr;
  grid-gap: 0;
}

.section {
  grid-gap: 10px;
  grid-template-columns: repeat(8, 1fr);
}

.apple {
  grid-template-columns: repeat(8, 1fr);
}

.banana {
  grid-template-columns: 3fr 1fr;
}

.ibm {
  grid-template-columns: repeat(1, 1fr);
}

.lenovo {
  grid-template-columns: repeat(8, 1fr);
}

.sgi {
  grid-template-columns: repeat(1, 1fr);
  border-radius: 10px;
  display: flex;
  align-items: end;
}

.arena {
  grid-template-columns: repeat(20, 1fr);
}

.padding-10 {
  padding: 10px;
}

@media (width <= 900px) {
  .lenovo {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (width <= 600px) {
  .lenovo {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 1200px) {
  .banana {
    grid-template-columns: 1fr;
  }

  .custom-vbar {
    justify-self: center;
    min-width: 400px;
    padding: 0;
  }
}